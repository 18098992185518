.z {
    background-color: #a78bfa;
    border-width: 2px;
    border-radius: 5px;
}

.h {
    background-color: #f87171;
    border-radius: 5px;
    border-width: 2px;
}   

.s {
    background-color: #facc15;
    border-radius: 5px;
    border-width: 2px;
}

.ss {
    background-color: #1e3a8a;
    border-radius: 5px;
    border-width: 2px;
}

