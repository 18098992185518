.mainContent {
    display: flex;
    flex-direction: column;
    position: relative;
}

/* 电脑的 */
@media screen and (min-width: 520px) {
    /* 华容道板 */
    .board {
        position: absolute;
        top: 16px;
        left: 16px;
        display: grid;
        width: 320px;
        grid-template-columns: 80px 80px 80px 80px;
        grid-template-rows: 80px 80px 80px 80px 80px;
        background-color: transparent;
    }

    .boardImage {
        display: block;
        position: relative;
        margin-bottom: 40px;
    }

    /* 状态栏 */
    .statusBar {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 352px;
        margin-bottom: 20px;
    }

    /* 动作栏 */
    .actionBar {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 352px;
    }

    .actionbtn {
        background-color: transparent;
        border: 0;
        flex: 1;
        height: 30px;
    }

    .actionbtn:hover {
        cursor: pointer;
        background-color: #bae6fd;
    }
}

.statusText {
    font-size: 28px;
    color: green;
    position: relative;
    left: 50%;
}

/* 手机的 */
@media screen and (max-width: 520px) {
    /* 华容道板 */
    .board {
        position: absolute;
        top: 11px;
        left: 11px;
        display: grid;
        width: 220px;
        grid-template-columns: 55px 55px 55px 55px;
        grid-template-rows: 55px 55px 55px 55px 55px;
        background-color: transparent;
    }

    .boardImage {
        display: block;
        position: relative;
        margin-bottom: 40px;
    }

    /* 状态栏 */
    .statusBar {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 242px;
        margin-bottom: 20px;
    }

    /* 动作栏 */
    .actionBar {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 242px;
    }

    .actionbtn {
        background-color: transparent;
        border: 0;
        width: 121px;
        height: 30px;
    }
}
