.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.title {
    font-size: 52px;
    text-align: center;
    margin-bottom: 50px;
}

.menubtn {
    position: relative;
    left: 35px;
    background-color: transparent;
    border: 0;
}

/* 手机的侧边栏 */
.sidebar {
    position: fixed;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.sidebarMain {
    position: relative;
    display: block;
    background-color: white;
}

.sidebarOver {
    position: relative;
    display: block;
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.8);
}

/* 华容道板和关卡选择器容器 */
.content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rightMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 3%;
}


.rightMenu h2 {
    text-align: center;
    margin-top: 0;
}

@media screen and (min-width: 520px) {
    .hideLarge {
        display: none !important;
    }
}

@media screen and (max-width: 520px) {
    .hideSmall {
        display: none !important;
    }
}
