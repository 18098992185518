/* 关卡选择 */
@media screen and (min-width: 520px) {
    .selector {
        display: grid;
        row-gap: 15px;
        grid-template-columns: 80px 80px 80px 80px;
    }

    .levelbtn {
        width: 60px;
        height: 25px;
        border-radius: 10px;
        border-width: 0px;
    }

    .levelbtn:hover {
        cursor: pointer;
        background-color: white;
    }
}

@media screen and (max-width: 520px) {
    .selector {
        display: grid;
        row-gap: 15px;
        grid-template-columns: 50px 50px 50px 50px;
    }

    .levelbtn {
        width: 40px;
        height: 20px;
        border-radius: 10px;
        border-width: 0px;
    }
}

.active {
    background-color: white;
}

.inactive {
    background-color: #f0f0f0;
}
